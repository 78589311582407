import React from 'react';

import StandardPage from '../components/StandardPage';
import Head from '../components/Head';

import '../style/_common.scss';

export default function schedule() {
  return (
    <StandardPage>
      <Head
        title="Schedule a Conversation"
        description="Questions about Bagel? Just grab a slot on our calendar and we'll reach out to you."
        url="/schedule/"
        image="/og_image.jpg"
      />
      <div
        className="fullpage"
        style={{ marginTop: '72px' }}
        dangerouslySetInnerHTML={{
          __html: `<div
        class="calendly-inline-widget"
        data-url="https://calendly.com/bagel"
        style="min-width:320px;height:99vh;"
      />
      <script
        type="text/javascript"
        src="https://assets.calendly.com/assets/external/widget.js"
      />`
        }}
      />
    </StandardPage>
  );
}
